import { ContentData } from '@innedit/innedit';
import { ContentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/produit/content.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PageEspaceProduitContent: FC<PageProps & UserProps & EspaceProps> = ({
  espace,
  user,
  params: { contentId, espaceId, produitId },
}) => {
  const contentData = new ContentData({
    espaceId,
    params,
    parentCollectionName: 'produits',
    parentId: produitId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form<ContentType>
          displayAdd={false}
          displayNavigation={false}
          displayView={false}
          docId={contentId}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/${produitId}/content/`}
          itemPathnameSuffix=""
          model={contentData}
          name="content"
          params={{ produitId }}
          title="Contenu"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProduitContent);
